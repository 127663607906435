import {Component, Input, OnInit} from '@angular/core';
import {CloseEditViewDelegate} from "../close-edit-view-delegate";
import {WorkreportDTO} from "../workreport-dto";
import moment from "moment";
import {FileService} from "../file.service";
import {FileDto} from "../file-dto";
import {ShippingOrderEntryDto} from "../shipping-order-entry-dto";
import {TimeBookingDTO} from "../time-booking-dto";
import {TimeBookingService} from "../time-booking.service";
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';
import {Lightbox} from "ngx-lightbox";

@Component({
  selector: 'app-work-report-print-view',
  templateUrl: './work-report-print-view.component.html',
  styleUrls: ['./work-report-print-view.component.scss']
})
export class WorkReportPrintViewComponent implements OnInit {
  @Input() delegate: CloseEditViewDelegate;
  @Input() workReport: WorkreportDTO;
  breakpoint = 6;
  allFiles: FileDto[] = [];
  timeBookings: TimeBookingDTO[] = [];

  private albums: any[] = [];

  constructor(private fileService: FileService, private timeBookingService: TimeBookingService, private lightbox: Lightbox) {
  }

  async ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    this.allFiles = await this.fileService.loadFilesOfWorkReport(this.workReport.id);
    this.allFiles.forEach(x => {
      this.loadPreview(x);
    });
    this.allFiles.forEach(x => {
      this.albums.push({id: x.id, src: ""});
    });

    this.timeBookings = await  this.timeBookingService.loadBookingsOfReport(this.workReport.id);
  }

  formatDay(timestamp: number) {
    return moment(timestamp).format('DD-MM-YYYY');
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  async loadPreview(fileDto: FileDto) {
    const reader = new FileReader();
    const blob = await this.fileService.getFileAsBlob(fileDto);
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      fileDto.src = reader.result;
      this.albums.find(item => item.id === fileDto.id).src = fileDto.src;
    };
  }

  formatTime(timestamp: number) {
    return moment(timestamp).format('HH:mm');
  }


  closeView() {
    this.delegate.closeEditView();
  }

  downloadPDF() {

    const node = document.getElementById('report');

    let img;
    let filename;
    let newImage;


    domtoimage.toPng(node, { bgcolor: '#fff' })

      .then(function(dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = function(){

          let pdfWidth = img.width;
          let pdfHeight = img.height;

          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          let doc;

          if(pdfWidth > pdfHeight)
          {
            doc = new jsPDF('l', 'px', [pdfWidth , pdfHeight]);
          }
          else
          {
            doc = new jsPDF('p', 'px', [pdfWidth , pdfHeight]);
          }


          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();


          doc.addImage(newImage, 'PNG',  10, 10, width, height);
          filename = 'Export-Arbeitsbericht' + '.pdf';
          doc.save(filename);

        };


      })
      .catch(function(error) {

        // Error Handling

      });
  }

  getTimeInHours(timeBooking: TimeBookingDTO) {
    const timeInMs = timeBooking.end - timeBooking.begin;
    const completeTime = timeInMs * timeBooking.assignedEmployees.length;
    return Math.round(((completeTime / 1000 / 60 / 60) + Number.EPSILON) * 100) / 100;
  }

  getCompleteTime() {
    let time = 0;
    if(this.timeBookings !== undefined && this.timeBookings !== null) {
      this.timeBookings.forEach(x => {
        time += this.getTimeInHours(x);
      });
      time = Math.round((time + Number.EPSILON) * 100) / 100
      return time;
    }
  }

  openLightbox(index: number): void {
    // open lightbox
    this.lightbox.open(this.albums, index);
  }
}
