<div fxLayout="row wrap" *ngIf="auth.BAUMON && bookingToEdit === null || bookingToEdit === undefined">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-title>
        Zeitbuchungs-Filter
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row wrap" class="text-uppercase">
          <div fxFlex="65%">
            <mat-form-field style="width: 100%" class="mb-1">
              <input matInput placeholder="Zum Filtern Text eingeben"
                     required (keyup)='updateFilter($event)'>
            </mat-form-field>
          </div>
          <div fxFlex="35%">
            <button mat-button aria-label="Neuen Zeitbuchung anlegen" matTooltip="Neuen Zeitbuchung anlegen" (click)="createBooking()">
              <mat-icon>add</mat-icon> Zeitbuchung
            </button>
            <button mat-button aria-label="Arbeitsberichte aktualisieren" matTooltip="Arbeitsberichte aktualisieren" (click)="loadBookings()">
              <mat-icon>refresh</mat-icon> Aktualisieren
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<mat-expansion-panel  class="panel" [expanded]="true" *ngIf="auth.BAUMON && bookingToEdit === null || bookingToEdit === undefined">
  <mat-expansion-panel-header>
    <mat-icon>alarm_on</mat-icon><p class="expansionHeader">Zeitbuchungen bearbeiten</p>
  </mat-expansion-panel-header>
  <ngx-datatable class='material workReports' [columnMode]="'force'" [sorts]="[{prop: 'created', dir: 'desc'}]" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10" [rows]='rows'>
    <ngx-datatable-column name="Datum" prop="begin" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        {{formatDay(value)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Beginn" prop="begin" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        {{formatTime(value)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Ende" prop="end" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        {{formatTime(value)}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Mitarbeiter" prop="assignedEmployees" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <ul>
          <li *ngFor="let employee of value">{{employee.name}}</li>
        </ul>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Aktionen" prop="id" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <button mat-flat-button color="accent" class="button" (click)="editBooking(row)"
                matTooltip="Arbeitsbericht bearbeiten">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-flat-button color="warn" class="button" (click)="delete(row)"
                matTooltip="Arbeitsbericht löschen">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <mat-action-row>
    <button mat-raised-button color="primary" (click)="closeView()" *ngIf="bookingToEdit === null || bookingToEdit === undefined">Zurück</button>
  </mat-action-row>

</mat-expansion-panel>

<div fxLayout="row wrap" *ngIf="bookingToEdit">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-title>
        Zeitbuchung bearbeiten
      </mat-card-title>
      <form fxLayout="column" [formGroup]="form">

      <mat-card-content class="pa-1">

          <mat-form-field class="formField">
            <input [formControl]="form.controls['day']"  name="day" (focus)="picker.open()" (click)="picker.open()" matInput [matDatepicker]="picker" placeholder="Datum">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <small *ngIf="form.controls['day'].hasError('required') && form.controls['day'].touched" class="mat-text-warn">Bitte wählen Sie ein Datum aus</small>
          </mat-form-field>
        <mat-form-field class="formField">
          <input name="begin" [formControl]="form.controls['begin']" matInput type="time" placeholder="Arbeitsbeginn">
        </mat-form-field>
        <mat-form-field class="formField">
          <input name="end" [formControl]="form.controls['end']" matInput type="time" placeholder="Arbeitsende">
        </mat-form-field>

          <ng-select placeholder="Mitarbeiter auswählen" [items]="employeeService.allUsers" bindLabel="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="bookingToEdit.assignedEmployees" multiple="true" name="materials"></ng-select>





      </mat-card-content>
      <mat-card-actions class="pa-1">
        <button mat-raised-button type="submit" class="shadow-none" (click)="updateBooking()">Speichern</button>
        <button mat-raised-button color="primary" (click)="abortEdit()">Abbrechen</button>
      </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
