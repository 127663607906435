<mat-toolbar class="main-header header-bg">
  <button (click)="toggleSidenav.emit()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <button (click)="fullScreenToggle()" mat-icon-button>
    <mat-icon>fullscreen</mat-icon>
  </button>

  <button [matMenuTriggerFor]="user" mat-icon-button class="ml-xs">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #user="matMenu" x-position="before">
    <h5 style="margin-left: 10px;" *ngIf="auth != undefined && auth.currentUser != undefined && auth.currentUser.username != undefined">{{auth.currentUser.username}}</h5>
    <button mat-menu-item (click)="openDialog()">
      <mat-icon>settings</mat-icon>
      Passwort ändern
    </button>
    <button mat-menu-item (click)="auth.logout()">
      <mat-icon>exit_to_app</mat-icon>
      Abmelden
    </button>
  </mat-menu>
</mat-toolbar>
