<div fxLayout="row wrap" *ngIf="authService.ADMIN">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-title>
        <div [innerHTML]="title"></div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row wrap" class="text-uppercase">
          <div fxFlex="90%">
            <mat-form-field style="width: 100%" class="mb-1">
              <input matInput placeholder="Geben Sie einen Namen ein um zu Filtern" required
                     (keyup)='updateFilter($event)'>
            </mat-form-field>
          </div>
          <div fxFlex="10%">
            <button  matTooltip="Datei hochladen" mat-icon-button aria-label="Datei hochladen" (click)="uploadInProgress = true" *ngIf="authService.ADMIN_SITE && !uploadInProgress">
              <mat-icon>publish</mat-icon>
            </button>
            <button *ngIf="workReportToView" mat-flat-button class="button" (click)="downloadPictures()"
                    matTooltip="Bilder Herunterladen">
              <mat-icon>description</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-expansion-panel  class="panel" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-icon>description</mat-icon><p class="expansionHeader">Dateien bearbeiten</p>
  </mat-expansion-panel-header>
  <ngx-datatable class='material' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10" [rows]='rows' *ngIf="!uploadInProgress">
    <ngx-datatable-column name="Name" prop="name" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <p>{{value}}</p>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Vorschau" [frozenLeft]="true" *ngIf="showPreview">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <img class="preview" src="{{row.src}}" />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Aktionen" prop="id" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <button  mat-icon-button aria-label="Datei öffnen"  (click)="download(row)"><mat-icon>open_in_browser</mat-icon></button>
        <button  mat-icon-button aria-label="Datei löschen"  (click)="delete(row.id)"><mat-icon>delete</mat-icon></button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <mat-form-field class="mb-1" *ngIf="uploadInProgress && showNameField">
    <mat-label>Dateiname</mat-label>
    <input matInput placeholder="Dateiname" [(ngModel)]="fileName" name="fileName">
  </mat-form-field>

  <div *ngIf="uploadInProgress">
    <input
      type="file"
      #file
      class="hidden form-control"
      id="files"
      (change)="detectFiles($event)"
      [accept]="accept"
      [multiple]="allowMulti"
    />


  </div>

  <mat-action-row>
    <button mat-raised-button color="primary" (click)="closeEditView()" *ngIf="!uploadInProgress && delegate !== null">Zurück</button>
    <button mat-raised-button color="primary" (click)="cancelUpload()" *ngIf="uploadInProgress">Abbrechen</button>
    <button mat-raised-button class="shadow-none" (click)="upload()" *ngIf="fileToUpload !== null && fileToUpload !== undefined">Datei Speichern</button>
  </mat-action-row>

</mat-expansion-panel>
