import {FileCategory} from "./file-category.enum";
import {SiteDTO} from "./site-dto";
import {BaseDTO} from "./base-dto";
import {WorkreportDTO} from "./workreport-dto";
import {EmployeeDto} from "./employee-dto";

export class FileDto extends BaseDTO {
  name: string;
  fileCategory: FileCategory;
  siteDTO: SiteDTO;
  fileType: string;
  workReportDTO: WorkreportDTO;
  src: any;
  employeeDTO: EmployeeDto;
}
