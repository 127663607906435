<div fxLayout="row wrap" *ngIf="auth.BAUMON && workReportToEdit === null || workReportToEdit === undefined">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-title>
        Arbeitsberichte-Filter
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row wrap" class="text-uppercase">
          <div fxFlex="65%">
            <mat-form-field style="width: 100%" class="mb-1">
              <input matInput placeholder="Zum Filtern Text eingeben"
                     required (keyup)='updateFilter($event)'>
            </mat-form-field>
          </div>
          <div fxFlex="35%">
            <button mat-button aria-label="Neuen Bericht anlegen" matTooltip="Neuen Bericht anlegen" (click)="createReport()">
              <mat-icon>add</mat-icon> Bericht
            </button>
            <button mat-button aria-label="Arbeitsberichte aktualisieren" matTooltip="Arbeitsberichte aktualisieren" (click)="load()">
              <mat-icon>refresh</mat-icon> Aktualisieren
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<mat-expansion-panel id="workReportList"  class="panel" [expanded]="true" *ngIf="auth.BAUMON && workReportToEdit === null || workReportToEdit === undefined">
  <mat-expansion-panel-header>
    <mat-icon>group_add</mat-icon><p class="expansionHeader">Arbeitsberichte bearbeiten</p>
  </mat-expansion-panel-header>
<ngx-datatable class='material workReports' [columnMode]="'force'" [sorts]="[{prop: 'created', dir: 'desc'}]" [headerHeight]="50" [footerHeight]="50"
               [rowHeight]="'auto'" [limit]="10" [rows]='rows'>
  <ngx-datatable-column name="Erstellt" prop="created" [frozenLeft]="true">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
      {{formatTimestamp(value)}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Bericht" prop="text" [frozenLeft]="true">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
      <div class="workReportContent" [innerHTML]="value"></div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Aktionen" prop="id" [frozenLeft]="true">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
      <button mat-flat-button color="accent" class="button" (click)="editWorkReport(row)"
              matTooltip="Arbeitsbericht bearbeiten">
        <mat-icon>create</mat-icon>
      </button>
      <button mat-flat-button color="accent" class="button" (click)="showFileList(row)"
              matTooltip="Dateien bearbeiten">
        <mat-icon>add_a_photo</mat-icon>
      </button>
      <button mat-flat-button color="accent" class="button" (click)="enableShippingOrder(row)"
              matTooltip="Lieferschein bearbeiten">
        <mat-icon>euro_symbol</mat-icon>
      </button>
      <button mat-flat-button color="accent" class="button" (click)="enableTimeBookings(row)"
              matTooltip="Zeitbuchungen bearbeiten">
        <mat-icon>alarm_on</mat-icon>
      </button>
      <button mat-flat-button color="accent" class="button" (click)="enablePrintView(row)"
              matTooltip="Druckansicht">
        <mat-icon>find_in_page</mat-icon>
      </button>
      <button mat-flat-button color="warn" class="button" (click)="deleteReport(row)"
              matTooltip="Arbeitsbericht löschen" *ngIf="auth.ADMIN_DELETE_WORK_REPORT">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
  <mat-action-row>
    <button mat-raised-button color="primary" (click)="closeView()" *ngIf="workReportToEdit === null || workReportToEdit === undefined">Zurück</button>
  </mat-action-row>

</mat-expansion-panel>

  <div fxLayout="row wrap" *ngIf="workReportToEdit && showReportEdit">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-card>
        <mat-card-title>
          Arbeitsbericht bearbeiten
        </mat-card-title>

        <mat-card-content class="pa-1">
          <form fxLayout="column">
            <div *ngIf="workReportToEdit.text === null || workReportToEdit.text === undefined || workReportToEdit.text === ''">
              <h3>Um den Arbeitsbericht zu speichern, gib eine Beschreibung ein!</h3>
            </div>
            <!--<mat-form-field class="mb-1">-->
              <ckeditor [editor]="Editor" [(ngModel)]="workReportToEdit.text" [config]="editorConfig" name="text"></ckeditor>
            <!--</mat-form-field>-->

          </form>
        </mat-card-content>
        <mat-card-actions class="pa-1">
          <button mat-raised-button class="shadow-none" [disabled]="workReportToEdit.text === null || workReportToEdit.text === undefined || workReportToEdit.text === ''" (click)="updateReport()">Speichern</button>
          <button mat-raised-button color="primary" (click)="abortEdit()">Abbrechen</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>


<app-work-report-print-view [delegate]="this" [workReport]="workReportToEdit"  *ngIf="workReportToEdit !== null && workReportToEdit !== undefined && showPrintView"></app-work-report-print-view>
<app-file-view [allowMulti]="true" [showPreview]="true" [accept]="getAccept()" [title]="getFileTitle()" [delegate]="this" [workReportToView]="workReportToEdit" *ngIf="workReportToEdit !== null && workReportToEdit !== undefined && showFileEdit"></app-file-view>
<app-shipping-order [delegate]="this" [currentReport]="workReportToEdit" *ngIf="workReportToEdit !== null && workReportToEdit !== undefined && showShippingOrder"></app-shipping-order>
<app-time-booking [delegate]="this" [workReport]="workReportToEdit" *ngIf="workReportToEdit !== null && workReportToEdit !== undefined && showTimeBookings"></app-time-booking>

