import {Component, Input, OnInit} from '@angular/core';
import {WorkreportDTO} from "../workreport-dto";
import {TimeBookingDTO} from "../time-booking-dto";
import {TimeBookingService} from "../time-booking.service";
import moment from "moment";
import {CloseEditViewDelegate} from "../close-edit-view-delegate";
import {AuthService} from "../auth.service";
import {EmployeeService} from "../employee.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DialogData, YesNoDialogComponent} from "../yes-no-dialog/yes-no-dialog.component";
import {MassUnitService} from "../mass-unit.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-time-booking',
  templateUrl: './time-booking.component.html',
  styleUrls: ['./time-booking.component.scss']
})
export class TimeBookingComponent implements OnInit {
  @Input() workReport: WorkreportDTO;
  @Input() delegate: CloseEditViewDelegate;
  picker: any;
  rows: TimeBookingDTO[];
  temp: TimeBookingDTO[];
  bookingToEdit: TimeBookingDTO;

  public form: UntypedFormGroup;

  constructor(private timeBookingService: TimeBookingService,
              public auth: AuthService,
              private employeeService: EmployeeService,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.form = this.fb.group({
      day: [
        null,
        Validators.compose([
          Validators.required,
        ])
      ],
      begin: [
        null,
        Validators.compose([
          Validators.required,
        ])
      ],
      end: [
        null,
        Validators.compose([
          Validators.required,
        ])
      ]
    });


    if(this.employeeService.allUsers === undefined || this.employeeService.allUsers === null) {
      this.employeeService.loadUsers();
    }

    this.loadBookings();
  }

  async loadBookings() {
    this.rows = await this.timeBookingService.loadBookingsOfReport(this.workReport.id);
    this.temp = this.rows;
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter((d) => {
      return this.formatDay(d.begin).toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
  }

  async delete(bookingDTO: TimeBookingDTO) {
    const dialogData: DialogData =  {
      desc: 'Zeitbuchung - Eintrag löschen?',
      text: 'Wollen Sie den Eintrag wirklich löschen?'
    };

    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '250px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.internalDelete(bookingDTO);
      }
    });
  }

  async internalDelete(bookingDTO: TimeBookingDTO) {
    await this.timeBookingService.deleteBooking(bookingDTO);
    this.loadBookings();
  }

  async updateBooking() {
    if(this.form.valid) {
      this.bookingToEdit.begin = this.getBegin().getTime();
      this.bookingToEdit.end = this.getEnd().getTime();
      await this.timeBookingService.updateOrCreate(this.bookingToEdit);
      this.bookingToEdit = null;
      this.loadBookings();
    }
  }

  getBegin(): Date {
    const day: Date = this.form.controls['day'].value.toDate();
    const begin: string = this.form.controls['begin'].value;
    const date = new Date(day.getFullYear(), day.getMonth(), day.getDate(), this.getHours(begin), this.getMinutes(begin));
    return date;
  }

  getEnd(): Date {
    const day: Date = this.form.controls['day'].value.toDate();
    const end: string = this.form.controls['end'].value;
    return new Date(day.getFullYear(), day.getMonth(), day.getDate(), this.getHours(end), this.getMinutes(end));
  }

  getHours(time: string) {
    return parseInt(time.split(':')[0]);
  }

  getMinutes(time: string) {
    return parseInt(time.split(':')[1]);
  }

  abortEdit() {
    this.bookingToEdit = null;
    this.loadBookings();
  }

  createBooking() {
    this.bookingToEdit = new TimeBookingDTO();
    this.bookingToEdit.workReportDTO = this.workReport;
  }

  closeView() {
    this.delegate.closeEditView();
  }

  editBooking(booking: TimeBookingDTO) {
    this.bookingToEdit = booking;
    this.form.controls['day'].setValue(moment.unix(this.bookingToEdit.begin / 1000));
    this.form.controls['begin'].setValue(moment.unix(this.bookingToEdit.begin / 1000).format("HH:mm"));
    this.form.controls['end'].setValue(moment.unix(this.bookingToEdit.end / 1000).format("HH:mm"));
  }

  formatTime(timestamp: number) {
    return moment(timestamp).format('HH:mm');
  }

  formatDay(timestamp: number) {
    return moment(timestamp).format('DD-MM-YYYY');
  }
 }
