import { Component, OnInit } from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {EmployeeService} from "../employee.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  error = false;
  pwd: string;
  pwd2: string;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>, public userService: EmployeeService) { }

  checkPwd() {
    const check = this.pwd.length > 0 && this.pwd === this.pwd2;
    this.error = !check;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  changePassword() {
    this.userService.changePasswordOfLocalUser(this.pwd);
    this.dialogRef.close();
  }

}
