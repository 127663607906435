import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimeBookingComponent} from "./time-booking.component";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {FileViewModule} from "../file-view/file-view.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {ShippingOrderModule} from "../shipping-order/shipping-order.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";



@NgModule({
  declarations: [TimeBookingComponent],
  exports: [
    TimeBookingComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    NgxDatatableModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    FormsModule,
    CKEditorModule,
    FileViewModule,
    MatExpansionModule,
    ShippingOrderModule,
    NgSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule
  ]
})
export class TimeBookingModule { }
