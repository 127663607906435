import {Component, Input, OnInit} from '@angular/core';
import {WorkreportService} from "../workreport.service";
import {WorkreportDTO} from "../workreport-dto";
import {ShippingOrderEntryDto} from "../shipping-order-entry-dto";
import {AuthService} from "../auth.service";
import {CloseEditViewDelegate} from "../close-edit-view-delegate";
import {MaterialService} from "../material.service";
import {MassUnitService} from "../mass-unit.service";
import {ancestorWhere} from "tslint";
import {DialogData, YesNoDialogComponent} from "../yes-no-dialog/yes-no-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-shipping-order',
  templateUrl: './shipping-order.component.html',
  styleUrls: ['./shipping-order.component.scss']
})
export class ShippingOrderComponent implements OnInit {
  @Input() currentReport: WorkreportDTO;
  @Input() delegate: CloseEditViewDelegate;
  entryToEdit: ShippingOrderEntryDto;
  workReport: WorkreportDTO;
  rows: ShippingOrderEntryDto[] = [];
  temp: ShippingOrderEntryDto[] = [];

  constructor(private workreportService: WorkreportService,
              public authService: AuthService,
              public materialService: MaterialService,
              public massUnitService: MassUnitService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.workReport = this.currentReport;
    this.rows = this.workReport.shippingOrderEntryDtos;
    this.temp = this.rows;

    if(this.materialService.allMaterials === undefined || this.materialService.allMaterials === null) {
      this.materialService.loadMaterial();
    }
    if(this.massUnitService.allUnits === undefined || this.massUnitService.allUnits === null) {
      this.massUnitService.loadUnits();
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter((d) => {
      return d.material.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
  }


  newEntry() {
    this.entryToEdit = new ShippingOrderEntryDto();
  }

  editEntry(entry: ShippingOrderEntryDto) {
    this.entryToEdit = entry;
  }

  async delete(entry: ShippingOrderEntryDto) {
    const dialogData: DialogData =  {
      desc: 'Lieferschein - Eintrag löschen?',
      text: 'Wollen Sie den Eintrag wirklich löschen?'
    };

    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '250px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.internalDelete(entry);
      }
    });
  }

  async internalDelete(entry: ShippingOrderEntryDto) {
    const idx = this.workReport.shippingOrderEntryDtos.findIndex(x => x.id === entry.id);
    if (idx > -1) {
      this.workReport.shippingOrderEntryDtos.splice(idx, 1);
    }
    this.workReport = await this.workreportService.updateOrCreate(this.workReport);
    this.rows = this.workReport.shippingOrderEntryDtos;
    this.temp = this.rows;
    this.entryToEdit = null;
  }

  closeEditView() {
    this.entryToEdit = null;
    this.delegate.closeEditView();
  }

  async cancelEdit() {
    this.entryToEdit = null;
    this.workReport = await this.workreportService.loadReport(this.workReport.id);
    this.rows = this.workReport.shippingOrderEntryDtos;
    this.temp = this.rows;
  }

  async saveEntry() {
    if(this.entryToEdit.id === undefined || this.entryToEdit.id === null) {
      this.workReport.shippingOrderEntryDtos.push(this.entryToEdit);
    }
    this.workReport = await this.workreportService.updateOrCreate(this.workReport);
    this.rows = this.workReport.shippingOrderEntryDtos;
    this.temp = this.rows;
    this.entryToEdit = null;
  }


}
