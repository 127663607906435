import {NgModule} from "@angular/core";
import {WorkReportViewComponent} from "./work-report-view.component";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {FileViewModule} from "../file-view/file-view.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {ShippingOrderModule} from "../shipping-order/shipping-order.module";
import {TimeBookingModule} from "../time-booking/time-booking.module";
import {WorkReportPrintViewModule} from "../work-report-print-view/work-report-print-view.module";


@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    NgxDatatableModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    FormsModule,
    CKEditorModule,
    FileViewModule,
    MatExpansionModule,
    ShippingOrderModule,
    TimeBookingModule,
    WorkReportPrintViewModule
  ],
  exports: [WorkReportViewComponent],
  declarations: [WorkReportViewComponent],
  providers: []
})
export class WorkReportViewModule {}
