import { Injectable } from '@angular/core';
import {MassUnitDto} from "./mass-unit-dto";
import {MassUnitUpdateEvent} from "./mass-unit-update-event";
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {MaterialDto} from "./material-dto";
import {MaterialUpdateEvent} from "./material-update-event";

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  allMaterials: MaterialDto[];
  eventListener: Array<MaterialUpdateEvent>;

  constructor(private auth: AuthService, private httpClient: HttpClient) {
    this.eventListener = new Array<MaterialUpdateEvent>();
  }

  registerUpdateListener(listener: MaterialUpdateEvent) {
    this.eventListener.push(listener);
  }

  async loadMaterial() {
    this.allMaterials = await this.getAllMaterials();
    this.eventListener.forEach(x => x.materialUpdated());
  }

  getAllMaterials(): Promise<MaterialDto[]> {
    const url = environment.backendUrl + "/material/list";
    return this.httpClient.get<MaterialDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async updateOrCreate(materialDto: MaterialDto): Promise<MaterialDto> {
    const url = environment.backendUrl + "/material";
    const savedDto: MaterialDto = await this.httpClient.post<MaterialDto>(url, materialDto, {headers: this.auth.headers}).toPromise();
    this.loadMaterial();
    return savedDto;
  }

  async deleteMaterial(materialDto: MaterialDto) {
    const url = environment.backendUrl + "/material/" + materialDto.id;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
    this.loadMaterial();
  }
}
