import { Injectable } from '@angular/core';
import {MassUnitDto} from "./mass-unit-dto";
import {SiteUpdateEvent} from "./site-update-event";
import {MassUnitUpdateEvent} from "./mass-unit-update-event";
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {SiteDTO} from "./site-dto";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MassUnitService {
  allUnits: MassUnitDto[];
  eventListener: Array<MassUnitUpdateEvent>;

  constructor(private auth: AuthService, private httpClient: HttpClient) {
    this.eventListener = new Array<MassUnitUpdateEvent>();
  }

  registerUpdateListener(listener: MassUnitUpdateEvent) {
    this.eventListener.push(listener);
  }

  async loadUnits() {
    this.allUnits = await this.getAllUnits();
    this.eventListener.forEach(x => x.massUnitUpdated());
  }

  getAllUnits(): Promise<MassUnitDto[]> {
    const url = environment.backendUrl + "/massUnit/list";
    return this.httpClient.get<MassUnitDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async updateOrCreate(unitDto: MassUnitDto): Promise<MassUnitDto> {
    const url = environment.backendUrl + "/massUnit";
    const savedDto: MassUnitDto = await this.httpClient.post<MassUnitDto>(url, unitDto, {headers: this.auth.headers}).toPromise();
    this.loadUnits();
    return savedDto;
  }

  async deleteUnite(unitDto: MassUnitDto) {
    const url = environment.backendUrl + "/massUnit/" + unitDto.id;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
    this.loadUnits();
  }
}
