import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ShippingOrderComponent} from "./shipping-order.component";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {FormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {NgSelectModule} from "@ng-select/ng-select";



@NgModule({
  declarations: [
    ShippingOrderComponent
  ],
  exports: [
    ShippingOrderComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    NgxDatatableModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    FormsModule,
    MatExpansionModule,
    NgSelectModule
  ]
})
export class ShippingOrderModule { }
