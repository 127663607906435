import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {SiteDTO} from "./site-dto";
import {SiteUpdateEvent} from "./site-update-event";
import {environment} from "../environments/environment";
import {TimeBookingDTO} from "./time-booking-dto";
import {ShippingOrderEntryDto} from "./shipping-order-entry-dto";

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  allSites: SiteDTO[];
  eventListener: Array<SiteUpdateEvent>;
  constructor(private auth: AuthService, private httpClient: HttpClient) {
    this.eventListener = new Array<SiteUpdateEvent>();
  }

  registerUpdateListener(listener: SiteUpdateEvent) {
    this.eventListener.push(listener);
  }

  async loadSites(showFinished: boolean) {
    this.allSites = await this.getAllSites(showFinished);
    this.eventListener.forEach(x => x.sitesUpdated());
  }

  async loadSite(id: string) {
    const url = environment.backendUrl + "/site/" + id;
    return this.httpClient.get<SiteDTO>(url, {headers: this.auth.headers}).toPromise();
  }

  getAllSites(showFinished: boolean): Promise<SiteDTO[]> {
    const url = environment.backendUrl + "/site/list?showFinished=" + showFinished;
    return this.httpClient.get<SiteDTO[]>(url, {headers: this.auth.headers}).toPromise();
  }

  getAllShippingOrderEntriesOfSite(siteId: string): Promise<ShippingOrderEntryDto[]> {
    const url = environment.backendUrl + "/site/" + siteId + "/shippingOrderEntries";
    return this.httpClient.get<ShippingOrderEntryDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async updateOrCreate(siteDto: SiteDTO): Promise<SiteDTO> {
    const url = environment.backendUrl + "/site";
    const savedDto: SiteDTO = await this.httpClient.post<SiteDTO>(url, siteDto, {headers: this.auth.headers}).toPromise();
    this.loadSites(false);
    return savedDto;
  }

  async deleteSite(siteDto: SiteDTO) {
    const url = environment.backendUrl + "/site/" + siteDto.id;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
    this.loadSites(false);
  }
}
