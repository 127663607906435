import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {WorkreportDTO} from "./workreport-dto";
import {TimeBookingDTO} from "./time-booking-dto";

@Injectable({
  providedIn: 'root'
})
export class TimeBookingService {

  constructor(private auth: AuthService, private httpClient: HttpClient) {

  }

  loadBookingsOfReport(reportId: string): Promise<any> {
    const url = environment.backendUrl + "/timeBooking/listByWorkReport/" + reportId;
    return this.httpClient.get<TimeBookingDTO[]>(url, {headers: this.auth.headers}).toPromise();
  }

  loadBookingsOfEmployee(employeeId: string, begin: number, end: number): Promise<any> {
    const url = environment.backendUrl + "/timeBooking/listDayBookingsByEmployee/" + employeeId + "?begin=" + begin + "&end=" + end;
    return this.httpClient.get<TimeBookingDTO[]>(url, {headers: this.auth.headers}).toPromise();
  }

  loadBookingsOfEmployeeOnWorkReports(employeeId: string, begin: number, end: number): Promise<any> {
    const url = environment.backendUrl + "/timeBooking/" + employeeId + "/clearableBookings?begin=" + begin + "&end=" + end;
    return this.httpClient.get<TimeBookingDTO[]>(url, {headers: this.auth.headers}).toPromise();
  }

  loadBookingsOfSite(siteId: string): Promise<any> {
    const url = environment.backendUrl + "/timeBooking/listBySite/" + siteId;
    return this.httpClient.get<TimeBookingDTO[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async updateOrCreate(timeBookingDto: TimeBookingDTO): Promise<TimeBookingDTO> {
    const url = environment.backendUrl + "/timeBooking";
    const savedDto: TimeBookingDTO = await this.httpClient.post<TimeBookingDTO>(url, timeBookingDto, {headers: this.auth.headers}).toPromise();
    return savedDto;
  }

  async deleteBooking(timeBookingDto: TimeBookingDTO) {
    const url = environment.backendUrl + "/timeBooking/" + timeBookingDto.id;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
  }

  loadBooking(bookingId: string): Promise<any> {
    const url = environment.backendUrl + "/timeBooking/" + bookingId;
    return this.httpClient.get<TimeBookingDTO>(url, {headers: this.auth.headers}).toPromise();
  }
}
