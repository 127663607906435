<div fxLayout="row wrap" *ngIf="authService.BAUMON">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-title>
        <h3>Lieferschein bearbeiten</h3>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row wrap" class="text-uppercase">
          <div fxFlex="70%">
            <mat-form-field style="width: 100%" class="mb-1">
              <input matInput placeholder="Geben Sie ein Material ein um zu Filtern" required
                     (keyup)='updateFilter($event)'>
            </mat-form-field>
          </div>
          <div fxFlex="30%">
            <button  matTooltip="Position hinzufügen" mat-button aria-label="Position hinzufügen" (click)="newEntry()" *ngIf="authService.BAUMON">
              <mat-icon>add</mat-icon> Lieferschein
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-expansion-panel  class="panel" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-icon>description</mat-icon><p class="expansionHeader">Lieferschein-Einträge bearbeiten</p>
  </mat-expansion-panel-header>
  <ngx-datatable class='material' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10" [rows]='rows' *ngIf="entryToEdit === null || entryToEdit === undefined">
    <ngx-datatable-column name="Material" prop="material" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <p>{{value}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Einheit" prop="massUnit" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <p>{{value}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Menge" prop="amount" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <p>{{value}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Kommentar" prop="comment" [frozenLeft]="true">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <p>{{value}}</p>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Aktionen" prop="id" [frozenLeft]="true">

      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <button  matTooltip="Position bearbeiten" mat-icon-button aria-label="Position bearbeiten" (click)="editEntry(row)" *ngIf="authService.BAUMON">
          <mat-icon>create</mat-icon>
        </button>
        <button  mat-icon-button aria-label="Datei löschen"  (click)="delete(row)"><mat-icon>delete</mat-icon></button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <form fxLayout="column" *ngIf="entryToEdit !== null && entryToEdit !== undefined">

    <ng-select placeholder="Material auswählen" [items]="materialService.allMaterials" bindLabel="name" bindValue="name" [(ngModel)]="entryToEdit.material" name="materials"></ng-select>
    <ng-select placeholder="Einheit auswählen" [items]="massUnitService.allUnits" bindLabel="name" bindValue="name" [(ngModel)]="entryToEdit.massUnit" name="units"></ng-select>

    <mat-form-field class="mb-1">
      <input type="number" matInput placeholder="Menge" [(ngModel)]="entryToEdit.amount" name="Amount">
    </mat-form-field>
    <mat-form-field class="mb-1">
      <input matInput placeholder="Kommentar" [(ngModel)]="entryToEdit.comment" name="Comment">
    </mat-form-field>


  </form>


  <mat-action-row>
    <button mat-raised-button color="primary" (click)="closeEditView()" *ngIf="entryToEdit == null || entryToEdit == undefined">Zurück</button>
    <button mat-raised-button color="primary" (click)="cancelEdit()" *ngIf="entryToEdit != null && entryToEdit != undefined">Abbrechen</button>
    <button mat-raised-button class="shadow-none" (click)="saveEntry()" *ngIf="entryToEdit != null && entryToEdit != undefined">Eintrag Speichern</button>
  </mat-action-row>

</mat-expansion-panel>
