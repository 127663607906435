<h1 mat-dialog-title>Passwort ändern</h1>
<div mat-dialog-content>
  <mat-form-field class="mb-1">
    <input matInput placeholder="New password" type="password" [(ngModel)]="pwd" (keyup)="checkPwd()">
  </mat-form-field>

  <mat-form-field class="mb-1">
    <input matInput placeholder="Confirm new password" type="password" [(ngModel)]="pwd2" (keyup)="checkPwd()">
  </mat-form-field>
  <div *ngIf="error">
    Die Passwörter stimmen nicht überein!
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-button (click)="changePassword()" [disabled]="error">Ändern</button>
</div>
