import {EmployeeDto} from "./employee-dto";
import {BaseDTO} from "./base-dto";
import {WorkreportDTO} from "./workreport-dto";

export class TimeBookingDTO extends BaseDTO{
  begin: number;
  end: number;
  assignedEmployees: EmployeeDto[];
  workReportDTO: WorkreportDTO;
  comment: string;
}
