import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {EmployeeDto} from "./employee-dto";
import {SiteDTO} from "./site-dto";
import {UserUpdateEvent} from "./user-update-event";
import {SiteUpdateEvent} from "./site-update-event";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  allUsers: EmployeeDto[];
  eventListener: Array<UserUpdateEvent>;

  constructor(private auth: AuthService, private httpClient: HttpClient) {
    this.eventListener = new Array<UserUpdateEvent>();
  }

  registerUpdateListener(listener: UserUpdateEvent) {
    this.eventListener.push(listener);
  }

  async loadUsers() {
    this.allUsers = await this.list();
    this.eventListener.forEach(x => x.userUpdated());
  }

  changePasswordOfLocalUser(password: string) {
    const url = environment.backendUrl + "/changePassword";
    this.httpClient.post(url, password, {headers: this.auth.headers}).toPromise().catch(ex => {
      console.log(ex);
    });
  }

  list(): Promise<any> {
    const url = environment.backendUrl + "/employee/list";
    return this.httpClient.get<EmployeeDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async updateOrCreate(siteDto: EmployeeDto): Promise<EmployeeDto> {
    const url = environment.backendUrl + "/employee";
    const savedDto: EmployeeDto = await this.httpClient.post<EmployeeDto>(url, siteDto, {headers: this.auth.headers}).toPromise();
    this.loadUsers();
    return savedDto;
  }

  async deleteEmployee(id: string) {
    const url = environment.backendUrl + "/employee/" + id;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
    this.loadUsers();
  }
}
