import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../auth.service";

@Injectable()
export class MenuService {

  constructor(public translate: TranslateService, private authService: AuthService) {}

  getAll() {
    const ret = new Array();

    if(!this.authService.ADMIN) {
      return [];
    }

    if(this.authService.ADMIN_SITE && this.authService.BAUMON) {
      ret.push(this.getSitesEntry());
    }

    if(this.authService.ADMIN_EMPLOYEE) {
      ret.push(this.getUsersEntry());
    }

    if(this.authService.ADMIN_NORM) {
      ret.push(this.getNormEntry());
    }

    if(this.authService.ADMIN_GPS) {
      ret.push(this.getGpsEntry());
    }

    if(this.authService.ADMIN_TIMEBOOKINGS) {
      ret.push(this.getDayBookingEntry());
    }

    if(this.authService.ADMIN_MASS_UNIT) {
      ret.push(this.getMassUnitsEntry());
    }

    if(this.authService.ADMIN_MATERIAL) {
      ret.push(this.getMaterialsEntry());
    }

    if(this.authService.WORKREPORT_TEMPLATES) {
      ret.push(this.getTemplatesEntry());
    }

    if(this.authService.ADMIN_EMPLOYEE_FILES) {
      ret.push(this.getUserFileEntry());
    }

    if(this.authService.ADMIN_CONTACTS) {
      ret.push(this.getContactInfoEntry());
    }

    console.log("getMenu");
    return ret;
  }

  getSitesEntry() {
    return {
      link: '/',
      label: 'Baustellen',
      icon: 'business'
    };
  }

  getContactInfoEntry() {
    return {
      link: '/contactTemplates',
      label: 'Kontakte',
      icon: 'phone_in_talk'
    };
  }

  getUsersEntry() {
    return {
      link: '/user',
      label: 'Benutzer',
      icon: 'person'
    };
  }

  getMassUnitsEntry() {
    return {
      link: '/massUnits',
      label: 'Mengen-Einheiten',
      icon: 'insert_chart_outlined'
    };
  }

  getMaterialsEntry() {
    return {
      link: '/materials',
      label: 'Materialien',
      icon: 'style'
    };
  }

  getTemplatesEntry() {
    return {
      link: '/vorlagen',
      label: 'Vorlagen',
      icon: 'insert_chart_outlined'
    };
  }

  getUserFileEntry() {
    return {
      link: '/userFiles',
      label: 'Benutzer Dateien',
      icon: 'folder_special'
    };
  }

  getNormEntry() {
    return {
      link: '/norm',
      label: 'Normen',
      icon: 'drafts'
    };
  }

  getGpsEntry() {
    return {
      link: '/gps',
      label: 'GPS Übersicht',
      icon: 'gps_fixed'
    };
  }

  getDayBookingEntry() {
    return {
      link: '/dayBooking',
      label: 'Zeitbuchungen',
      icon: 'alarm_on'
    };
  }
}
