import { Injectable } from '@angular/core';
import {FileDto} from "./file-dto";
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {FileCategory} from "./file-category.enum";
import {EmployeeDto} from "./employee-dto";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private auth: AuthService, private httpClient: HttpClient) { }

  upload(fileDto: FileDto, file: any): Promise<any> {
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append("fileDto", JSON.stringify(fileDto));
    return this.httpClient.post(environment.backendUrl + "/file/upload", formData, {headers: this.auth.headers}).toPromise();
  }

  loadFilesOfSite(siteId: string): Promise<any> {
    const url = environment.backendUrl + "/file/listbysite/" + siteId;
    return this.httpClient.get<FileDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  loadFilesOfWorkReport(workReportId: string): Promise<any> {
    const url = environment.backendUrl + "/file/listbyworkreport/" + workReportId;
    return this.httpClient.get<FileDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  loadFilesOfCategory(category: FileCategory): Promise<any> {
    const url = environment.backendUrl + "/file/listbycategory/" + category;
    return this.httpClient.get<FileDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  loadFilesOfEmployee(employee: EmployeeDto): Promise<any> {
    const url = environment.backendUrl + "/file/listByEmployee/" + employee.id;
    return this.httpClient.get<FileDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async download(fileDto: FileDto) {
    const blob = await this.getFileAsBlob(fileDto);
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

  async getZip(id: string) {
    const blob = await this.getZipAsBlob(id);
    let objUrl = window.URL.createObjectURL(blob);
    let pwa = window.open(objUrl);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

  async getZipAsBlob(id: any) {
    const response = await this.httpClient.get(environment.backendUrl + "/file/downloadWorkReport/" + id,{
      responseType: 'arraybuffer', headers: this.auth.headers}
    ).toPromise();

    return new Blob([response], {type: "application/zip"});
  }

  async getFileAsBlob(fileDto: FileDto) {
    const response = await this.httpClient.get(environment.backendUrl + "/file/download/" + fileDto.id,{
      responseType: 'arraybuffer', headers: this.auth.headers}
    ).toPromise();

    return new Blob([response], {type: fileDto.fileType});
  }


  deleteFromSite(fileId): Promise<any> {
    return this.httpClient.delete(environment.backendUrl + "/file/deletefromSite/" + fileId, {headers: this.auth.headers}).toPromise();
  }

  deleteFromReport(fileId) {
    return this.httpClient.delete(environment.backendUrl + "/file/deleteFromReport/" + fileId, {headers: this.auth.headers}).toPromise();
  }

  deleteFromCategory(fileId) {
    return this.httpClient.delete(environment.backendUrl + "/file/deleteFromCategory/" + fileId, {headers: this.auth.headers}).toPromise();
  }

  deleteFromEmployee(fileId) {
    return this.httpClient.delete(environment.backendUrl + "/file/deleteFromEmployee/" + fileId, {headers: this.auth.headers}).toPromise();
  }
}
