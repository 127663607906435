import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SiteDTO} from "../site-dto";
import {FileDto} from "../file-dto";
import {FileService} from "../file.service";
import {AuthService} from "../auth.service";
import {SiteService} from "../site.service";
import {CloseEditViewDelegate} from "../close-edit-view-delegate";
import {WorkreportDTO} from "../workreport-dto";
import {relativeTimeRounding} from "moment";
import {DialogData, YesNoDialogComponent} from "../yes-no-dialog/yes-no-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {FileCategory} from "../file-category.enum";
import {EmployeeDto} from "../employee-dto";

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit, OnChanges {

  @Input() siteToView: SiteDTO;
  @Input() workReportToView: WorkreportDTO
  @Input() employee: EmployeeDto;
  @Input() delegate: CloseEditViewDelegate;
  @Input() title: string;
  @Input() accept: string;
  @Input() showPreview: boolean;
  @Input() fileCategory: FileCategory;
  @Input() showNameField = false;
  @Input() allowMulti = false;
  fileName: string;
  uploadInProgress = false;
  fileToUpload: any;
  rows: FileDto[] = [];
  temp: FileDto[] = [];

  constructor(public authService: AuthService, private fileService: FileService, private siteService: SiteService, public dialog: MatDialog) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.loadFilesOfSite();
  }

  ngOnInit() {
    this.loadFilesOfSite();
  }

  detectFiles(event) {
    const file = event.target.files;
    if (file !== undefined || file !== null) {
      this.fileToUpload = file;
    }
  }

  async upload() {
    if(this.siteToView !== null && this.siteToView !== undefined && (this.siteToView.id === null || this.siteToView.id === undefined)) {
      this.siteToView = await this.siteService.updateOrCreate(this.siteToView);
    }
    const fileDto: FileDto = new FileDto();

    if(this.siteToView !== undefined && this.siteToView !== null) {
      fileDto.siteDTO = this.siteToView;
    } else if(this.workReportToView !== undefined && this.workReportToView !== null) {
      fileDto.workReportDTO = this.workReportToView;
    } else if(this.fileCategory !== undefined && this.fileCategory !== null) {
      fileDto.fileCategory = this.fileCategory;
    } else if(this.employee !== undefined && this.employee !== null) {
      fileDto.employeeDTO = this.employee;
    }

    if(this.fileName !== undefined && this.fileName !== null) {
      fileDto.name = this.fileName;
    }

    for (let i = 0; i < this.fileToUpload.length; i++) {
      fileDto.fileType = this.fileToUpload[i].type;
      await this.fileService.upload(fileDto, this.fileToUpload[i]);
    }
    this.fileName = null;
    this.loadFilesOfSite();
    this.fileToUpload = null;
    this.uploadInProgress = false;
  }

  async loadFilesOfSite() {
    if(this.siteToView !== undefined && this.siteToView !== null) {
      this.rows = await this.fileService.loadFilesOfSite(this.siteToView.id);
    } else if(this.workReportToView !== undefined && this.workReportToView !== null) {
      this.rows = await this.fileService.loadFilesOfWorkReport(this.workReportToView.id);
    } else if(this.fileCategory !== undefined && this.fileCategory !== null) {
      this.rows = await this.fileService.loadFilesOfCategory(this.fileCategory);
    } else if(this.employee !== undefined && this.employee !== null) {
      this.rows = await this.fileService.loadFilesOfEmployee(this.employee);
    }

    if(this.showPreview) {
      this.rows.forEach(x => {
        this.loadPreview(x);
      });
    }

    this.temp = this.rows;
  }

  async loadPreview(fileDto: FileDto) {
    const reader = new FileReader();
    const blob = await this.fileService.getFileAsBlob(fileDto);
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      fileDto.src = reader.result;
    };
  }

  download(file: FileDto) {
    this.fileService.download(file);
  }

  async delete(fileId: string) {
    const dialogData: DialogData =  {
      desc: 'Datei löschen?',
      text: 'Wollen Sie die Datei wirklich löschen?'
    };

    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '250px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.internalDelete(fileId);
      }
    });


  }

  async internalDelete(fileId: string) {
    if(this.siteToView !== undefined && this.siteToView !== null) {
      await this.fileService.deleteFromSite(fileId);
    } else if(this.workReportToView !== undefined && this.workReportToView !== null) {
      await this.fileService.deleteFromReport(fileId);
    } else if(this.fileCategory !== undefined && this.fileCategory !== null) {
      await this.fileService.deleteFromCategory(fileId);
    } else if(this.employee !== null && this.employee !== undefined) {
      await this.fileService.deleteFromEmployee(fileId);
    }

    this.loadFilesOfSite();
  }

  cancelUpload() {
    this.fileToUpload = null;
    this.uploadInProgress = false;
  }

  closeEditView() {
    this.delegate.closeEditView();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
  }

  downloadPictures() {
    this.fileService.getZip(this.workReportToView.id);
  }
}
