export class Rights {
  static ADMIN = 'ADMIN';
  static ADMIN_EMPLOYEE = 'ADMIN_EMPLOYEE';
  static ADMIN_SITE = 'ADMIN_SITE';
  static ADMIN_MATERIAL = 'ADMIN_MATERIAL';
  static WORKREPORT_TEMPLATES = "ADMIN_WORKREPORT_TEMPLATES";
  static ADMIN_MASS_UNIT = 'ADMIN_MASS_UNIT';
  static ADMIN_NORM = 'ADMIN_NORM';
  static ADMIN_GPS = 'ADMIN_GPS';
  static ADMIN_TIMEBOOKINGS = 'ADMIN_TIMEBOOKINGS';
  static BAUMON = 'BAUMON';
  static BAUMON_WORKREPORT = 'BAUMON_WORKREPORT';
  static ADMIN_DELETE_SITE = 'ADMIN_DELETE_SITE';
  static ADMIN_DELETE_WORK_REPORT = 'ADMIN_DELETE_WORK_REPORT';
  static ADMIN_EMPLOYEE_FILES = 'ADMIN_EMPLOYEE_FILES';
  static ADMIN_CONTACTS = 'ADMIN_CONTACTS';
}
