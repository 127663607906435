import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SiteDTO} from "../site-dto";
import {WorkreportDTO} from "../workreport-dto";
import {WorkreportService} from "../workreport.service";
import {AuthService} from "../auth.service";
import {DialogData, YesNoDialogComponent} from "../yes-no-dialog/yes-no-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CloseEditViewDelegate} from "../close-edit-view-delegate";
import moment from "moment";
import {FileService} from "../file.service";

@Component({
  selector: 'app-work-report-view',
  templateUrl: './work-report-view.component.html',
  styleUrls: ['./work-report-view.component.scss']
})
export class WorkReportViewComponent implements OnInit, CloseEditViewDelegate {
  @Input() siteToView: SiteDTO;
  @Input() delegate: CloseEditViewDelegate;

  workReportToEdit: WorkreportDTO = null;
  public Editor = ClassicEditor;
  showReportEdit = false;
  showFileEdit = false;
  showShippingOrder = false;
  showTimeBookings = false;
  showPrintView = false;

  rows: WorkreportDTO[];
  temp: WorkreportDTO[];
  editorConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList' ],
    height: '100'
  };
  constructor(private workReportService: WorkreportService, public auth: AuthService, public dialog: MatDialog, private cdRef: ChangeDetectorRef, private fileService: FileService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    this.rows = await this.workReportService.loadReportsOfSite(this.siteToView.id);
    this.temp = this.rows;
    const element = document.getElementById("workReportList");
    element.scrollIntoView();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter((d) => {
      return d.text.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
  }

  deleteReport(report: WorkreportDTO): void {
    const dialogData: DialogData =  {
      desc: 'Arbeitsbericht vom ' + this.formatTimestamp(report.created) + ' löschen?',
      text: 'Wollen Sie den Arbeitsbericht vom ' + this.formatTimestamp(report.created) + ' wirklich löschen?'
    };

    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '250px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.delete(report);
      }
    });
  }

  async delete(report: WorkreportDTO) {
    await this.workReportService.deleteWorkReport(report);
    this.load();
  }

  async updateReport() {
    await this.workReportService.updateOrCreate(this.workReportToEdit);
    this.workReportToEdit = null;
    this.showReportEdit = false;
    this.load();
  }

  abortEdit() {
    this.workReportToEdit = null;
    this.showReportEdit = false;
    this.load();
  }

  createReport() {
    this.workReportToEdit = new WorkreportDTO();
    this.workReportToEdit.text = "Bitte Arbeitsschritte eingeben";
    this.workReportToEdit.siteDTO = this.siteToView;
    this.showReportEdit = true;
  }

  formatTimestamp(timestamp: number) {
    return moment(timestamp).format('DD-MM-YYYY HH:mm');
  }

  closeEditView() {
    this.workReportToEdit = null;
    this.showReportEdit = false;
    this.showFileEdit = false;
    this.showShippingOrder = false;
    this.showPrintView = false;
    this.showTimeBookings = false;
    this.load();
  }

  closeView() {
    this.delegate.closeEditView();
  }

  showFileList(report: WorkreportDTO) {
    this.workReportToEdit = report;
    this.showFileEdit = true;
  }

  enableShippingOrder(report: WorkreportDTO) {
    this.workReportToEdit = report;
    this.showShippingOrder = true;
  }

  enablePrintView(report: WorkreportDTO) {
    this.workReportToEdit = report;
    this.showPrintView = true;
  }

  editWorkReport(report: WorkreportDTO) {
    this.workReportToEdit = report;
    this.showReportEdit = true;
  }

  enableTimeBookings(report: WorkreportDTO) {
    this.workReportToEdit = report;
    this.showTimeBookings = true;
  }

  getFileTitle() {
    return "<h2>Dateien des Arbeitsberichts vom " + this.formatTimestamp(this.workReportToEdit.created) + " bearbeiten";
  }

  getAccept() {
    return "image/png,image/jpeg";
  }
}
