import {NgModule} from "@angular/core";
import {FileViewComponent} from "./file-view.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule} from "@angular/forms";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";

@NgModule({
  imports: [
    NgxDatatableModule,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    MatTooltipModule
  ],
  exports: [FileViewComponent],
  declarations: [FileViewComponent],
  providers: []
})
export class FileViewModule {}
