import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WorkReportPrintViewComponent} from "./work-report-print-view.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import { LightboxModule } from 'ngx-lightbox';



@NgModule({
  declarations: [WorkReportPrintViewComponent],
  exports: [
    WorkReportPrintViewComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    NgxDatatableModule,
    MatGridListModule,
    MatIconModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatButtonModule,
    LightboxModule
  ]
})
export class WorkReportPrintViewModule { }
