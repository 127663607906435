import { Routes } from '@angular/router';

import { AdminLayoutComponent, AuthLayoutComponent } from './core';

export const AppRoutes: Routes = [{
  path: '',
  component: AdminLayoutComponent,
  children: [
    {
      path: 'user',
      loadChildren: () => import('./user-admin/user-admin.module').then(m => m.UserAdminModule)
    },
    {
      path: 'massUnits',
      loadChildren: () => import('./mass-unit/mass-unit.module').then(m => m.MassUnitModule)
    },
    {
      path: 'materials',
      loadChildren: () => import('./material/material.module').then(m => m.MaterialModule)
    },
    {
      path: 'dayBooking',
      loadChildren: () => import('./day-booking/day-booking.module').then(m => m.DayBookingModule)
    },
    {
      path: 'gps',
      loadChildren: () => import('./gps/gps.module').then(m => m.GpsModule)
    },
    {
      path: 'norm',
      loadChildren: () => import('./norm/norm.module').then(m => m.NormModule)
    },
    {
      path: 'userFiles',
      loadChildren: () => import('./user-file/user-file.module').then(m => m.UserFileModule)
    },
    {
      path: 'vorlagen',
      loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
    },
    {
      path: '',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'contactTemplates',
      loadChildren: () => import('./contact-template/contact-template.module').then(m => m.ContactTemplateModule)
    }
    ]},
  {
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: 'session',
    loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
  }]
},
  {
  path: '**',
  redirectTo: 'session/404'
}];
