<button mat-icon-button class="downloadBtn"  (click)="downloadPDF()" >
  <mat-icon>
    picture_as_pdf
  </mat-icon>
</button>
<div id="report">
<mat-card>
  <mat-card-title>
    <div class="mb-1">
      <h1 class="mt-0"><strong>Arbeitsbericht von Baustelle: {{workReport.siteDTO.name}}</strong></h1>
      <h3>{{workReport.siteDTO.address}}</h3>
      <h4>Erstellungsdatum: {{formatDay(workReport.created)}}</h4>
    </div>
  </mat-card-title>
  <mat-card-content class="py-1">
    <div fxLayout="row wrap">
      <div fxFlex.gt-xs="50" fxFlex="100">
        <h3 class="my-0">Beschreibung:</h3>
        <div [innerHTML]="workReport.text" fxLayout="column">
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>
    Eingesetztes Material:
  </mat-card-title>
  <mat-card-content class="pl-0 pr-0 py-1">
    <ngx-datatable class='material' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                   [rowHeight]="'auto'" [limit]="1000" [rows]='workReport.shippingOrderEntryDtos'>
      <ngx-datatable-column name="Material" prop="material" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          <p>{{value}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Einheit" prop="massUnit" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          <p>{{value}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Menge" prop="amount" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          <p>{{value}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Kommentar" prop="comment" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          <p>{{value}}</p>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-card-content>
</mat-card>
<mat-card >
  <mat-card-title>Zeit-Buchungen Gesamt-Zeit: {{getCompleteTime()}} Stunden</mat-card-title>
  <mat-card-content>
    <ngx-datatable class='material workReports' [columnMode]="'force'" [sorts]="[{prop: 'created', dir: 'desc'}]" [headerHeight]="50" [footerHeight]="50"
                   [rowHeight]="'auto'" [limit]="1000" [rows]='timeBookings'>
      <ngx-datatable-column name="Datum" prop="begin" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          {{formatDay(value)}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Beginn" prop="begin" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          {{formatTime(value)}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Ende" prop="end" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          {{formatTime(value)}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Mitarbeiter" prop="assignedEmployees" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          <ul>
            <li *ngFor="let employee of value">{{employee.name}}</li>
          </ul>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Zeit" [frozenLeft]="true">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
          {{getTimeInHours(row)}} Stunden
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-title>
    Bilder
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" *ngFor="let file of allFiles; index as i">
        <mat-card>
          <mat-card-title>
            {{file.name}}
          </mat-card-title>
          <mat-card-content>
            <div class="imgContainer">
              <img (click)="openLightbox(i)" class="preview" src="{{file.src}}" />
            </div>
          </mat-card-content>
        </mat-card>

      </div>
    </div>
  </mat-card-content>
</mat-card>
</div>
<mat-action-row>
  <button mat-raised-button color="primary" class="btnBack" (click)="closeView()" >Zurück</button>
</mat-action-row>






