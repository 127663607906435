import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {FileDto} from "./file-dto";
import {WorkreportDTO} from "./workreport-dto";
import {SiteDTO} from "./site-dto";

@Injectable({
  providedIn: 'root'
})
export class WorkreportService {

  constructor(private auth: AuthService, private httpClient: HttpClient) { }

  loadReportsOfSite(siteId: string): Promise<any> {
    const url = environment.backendUrl + "/workreport/listBySite/" + siteId;
    return this.httpClient.get<WorkreportDTO[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async updateOrCreate(workReportDto: WorkreportDTO): Promise<WorkreportDTO> {
    const url = environment.backendUrl + "/workreport";
    const savedDto: WorkreportDTO = await this.httpClient.post<WorkreportDTO>(url, workReportDto, {headers: this.auth.headers}).toPromise();
    return savedDto;
  }

  async deleteWorkReport(workReport: WorkreportDTO) {
    const url = environment.backendUrl + "/workreport/" + workReport.id;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
  }

  loadReport(reportId: string): Promise<any> {
    const url = environment.backendUrl + "/workreport/" + reportId;
    return this.httpClient.get<WorkreportDTO>(url, {headers: this.auth.headers}).toPromise();
  }
}
